import React, { useState, useEffect } from 'react';
import './imageRotator.css';

const ImageRotator = ({ images }) => {
    const [currentImage, setCurrentImage] = useState(images[0]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentImage(currentImage => {
                const index = (currentIndex + 1) % Math.min(images.length, 5);
                setCurrentIndex(index);
                return images[index];
            });
        }, 5000);
        return () => clearInterval(timer);
    }, [currentIndex]);

    return (
        <div className="image-rotator-container">
            <div className="image-rotator">
                <img src={currentImage} alt="Rotating" />
                <div className="circles">
                    {Array.from({ length: Math.min(images.length, 5) }, (_, i) => (
                        <div className={`circle ${i === currentIndex ? 'filled' : ''}`} key={i} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ImageRotator;
