import React from 'react';
import Header from './desktop/header';
import Footer from "./desktop/footer";
import ImageRotator from "./desktop/imageRotator";
import image1 from './resources/ex1.jpg';
import image2 from './resources/ex2.jpg';
import image3 from './resources/ex3.jpg';
import Category from "./desktop/category";

function App() {
    const BasketsCategory = {
        name: "Baskets",
        desc: "Personalized baskets that are perfect for any room in your home. \n They can be used to store toys, organize your room, or even as a laundry basket.",
        bullets: [
            'A wide variety of baskets in all sizes and types, depending on inventory availability',
            '(For information on available inventory, please contact us using the WhatsApp button below)',
            'You can add a name or element of your choice',
            'Comes in a gift box',
            'Preparation time: 10 business days',
            'The baskets are handmade, so there may be slight size changes and color differences',
            'The color or size of the basket cannot be changed',
            'Customization changes can be made',
        ]
    }

    const DreamcatcherCategory = {
        name: "Dreamcatchers",
        desc: "Handmade dreamcatchers perfect for your home decor.",
        bullets: [
            'Each dreamcatcher is unique and handmade',
            'You can request customization',
            'Comes in a gift box',
            'Preparation time: 10 business days',
            'There may be slight size changes and color differences',
            'The color or size of the dreamcatcher cannot be changed',
            'Customization changes can be made',
        ]
    }

    const WallDecorCategory = {
        name: "Wall Decor",
        desc: "Beautiful wall decor for your home.",
        bullets: [
            'Each piece is unique and handmade',
            'You can request customization',
            'Comes in a gift box',
            'Preparation time: 10 business days',
            'There may be slight size changes and color differences',
            'The color or size of the decor cannot be changed',
            'Customization changes can be made',
        ]
    }

    const BowsCategory = {
        name: "Bows",
        desc: "Handmade bows perfect for any occasion.",
        bullets: [
            'Each bow is unique and handmade',
            'You can request customization',
            'Comes in a gift box',
            'Preparation time: 10 business days',
            'There may be slight size changes and color differences',
            'The color or size of the bow cannot be changed',
            'Customization changes can be made',
        ]
    }

    return (
        <div className="App">
            <Header/>
            <ImageRotator images={[image1, image2, image3]}/>
            <Category
                name={BasketsCategory.name}
                desc={ BasketsCategory.desc}
                bullets={BasketsCategory.bullets}
            />
            <Category
                name={DreamcatcherCategory.name}
                desc={ DreamcatcherCategory.desc}
                bullets={DreamcatcherCategory.bullets}
            />
            <Category
                name={WallDecorCategory.name}
                desc={ WallDecorCategory.desc}
                bullets={WallDecorCategory.bullets}
            />
            <Category
                name={BowsCategory.name}
                desc={ BowsCategory.desc}
                bullets={BowsCategory.bullets}
            />
            <Footer/>
        </div>);
}

export default App;
