import React from 'react';
import './footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="footer">
            <hr />
            <ul>
                <li><a href="/shipping-and-returns">Shipping and Returns Policy</a></li>
                <li><a href="/terms-of-use">Terms of Use</a></li>
                <li><a href="/about">About</a></li>
                <li><a href="/contact-us">Contact Us</a></li>
            </ul>
            <div>
                <a href="https://www.instagram.com/invites/contact/?i=19ml8aem329am&utm_content=k9y3nhg" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
                <a href="https://www.facebook.com/youraccount" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
            </div>
        </footer>
    );
};

export default Footer;
