import React from 'react';

const ProductBox = ({ name, price, image }) => {
    const style = {
        productBox: {
            margin: '10px',
        },
        img: {
            maxWidth: '100%', // make the image scale down if it's too wide
            maxHeight: '100%', // make the image scale down if it's too tall
        },
        productName: {
            margin: '5px 0', /* Adjust as needed */
        },
        productPrice: {
            margin: '5px 0', /* Adjust as needed */
            fontSize: '0.8em', // make the font size smaller
        }
    };

    return (
        <div style={style.productBox}>
            <img src={image} alt="Product" style={style.img} />
            <h3 style={style.productName}>{name}</h3>
            <p style={style.productPrice}>Price: {price}  ₪</p>
        </div>
    );
};

export default ProductBox;
