import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo.png';
import './header.css';

const Header = () => {
    return (
        <header className="header-component">
            <div>
                <img src={logo} alt="Logo" className="logos"/>
            </div>
            <nav className="navbar">
                <ul>
                    <li><a href="#baskets">Baskets </a></li>
                    <li><a href="#dreamcatchers"> Dreamcatchers</a></li>
                    <li><a href="#wall-decor">Wall Decor</a></li>
                    <li><a href="#bows"> Rainbows</a></li>
                </ul>
            </nav>
        </header>
    );
};

export default Header
