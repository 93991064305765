import React from 'react';
import im1 from '../resources/ex1.jpg';
import im2 from '../resources/ex2.jpg';
import im3 from '../resources/ex3.jpg';
import ProductBox from "./productBox.js";

const styles = {
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        // height: '100vh',
        scrollMarginTop: '100px',
        marginBottom: '20px', // adjust this value as needed
    },
    textPanel: {
        padding: '10px',
        overflowY: 'auto',
        textAlign: 'left',
        color: '#8ba0a1',
    },
    imagePanel: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        overflow: 'auto',
        width: '100%',
        flexWrap: 'wrap', // allow the items to wrap onto multiple lines
    },
    imagePanelDiv: {
        flex: '0 0 calc(33.33% - 20px)', // subtract the total margin from the width
        textAlign: 'center',
        margin: '0 10px',
    },

    imagePanelImg: {
        maxWidth: '100%',
        height: 'auto',
    },
};
const Category = ({name, desc, bullets}) => {
    return (
        <fr>
            <div id={name.toLowerCase().replace(' ', '-')} style={styles.pageContainer}>
                <div style={styles.textPanel}>
                    <h2 style={styles.title}>
                        {name}
                    </h2>

                    <p style={styles.description}>
                        {desc}
                    </p>

                    <h3 style={styles.details}>
                        Details:
                    </h3>

                    <ul style={styles.list}>
                        {bullets && bullets.map((bullet) => (<li>{bullet}</li>))}
                    </ul>

                </div>
                <div style={styles.imagePanel}>
                    {productData.map((product, index) => (
                        <div style={styles.imagePanelDiv}>
                            <ProductBox
                                key={index}
                                style={styles.productBox}
                                image={product.image}
                                price={product.price}
                                name={product.name}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <hr/>
        </fr>

    );
};


export default Category;

const productData = [
    {
        image: im1,
        price: 100,
        name: "Noa"
    },
    {
        image: im2,
        price: 200,
        name: "Props"
    },
    {
        image: im3,
        price: 300,
        name: "Turtle"
    },
    // Add more products as needed...
];
